import * as React from "react"
import {ColorMode, ToggleButton, ToggleButtonGroup, VisuallyHidden} from "@aws-amplify/ui-react";
import BedtimeIcon from '@mui/icons-material/Bedtime';
import TonalityIcon from '@mui/icons-material/Tonality';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import {useLunettes} from "provider/LunettesProvider";

export default () => {
    const {colorMode, setColorMode} = useLunettes();
    return <ToggleButtonGroup
        value={colorMode}
        size="small"
        onChange={(value) => setColorMode(value as ColorMode)}
        isExclusive
        isSelectionRequired
        className="color-switcher"
    >
        <ToggleButton value="light">
            <VisuallyHidden>Light mode</VisuallyHidden>
            <WbSunnyIcon/>
        </ToggleButton>
        <ToggleButton value="dark">
            <VisuallyHidden>Dark mode</VisuallyHidden>
            <BedtimeIcon/>
        </ToggleButton>
        <ToggleButton value="system">
            <VisuallyHidden>System preference</VisuallyHidden>
            <TonalityIcon/>
        </ToggleButton>
    </ToggleButtonGroup>;
};