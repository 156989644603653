import * as React from "react"
import {Menu, MenuButton, MenuItem, Text, View} from "@aws-amplify/ui-react";
import {useI18next} from "gatsby-plugin-react-i18next";
import "flagpack/dist/flagpack.css";
import {useLunettes} from "provider/LunettesProvider";

const languageConfig: { [key: string]: { name: string, countryCode: string } } = {
    ja: {
        name: '日本語',
        countryCode: 'jp'
    },
    en: {
        name: 'English',
        countryCode: 'gb'
    }
}

export default () => {
    const {language, languages} = useI18next();
    const {setLanguage} = useLunettes();

    return <View>
        <Menu trigger={
            <MenuButton width="100%">
                <Language language={language}/>
            </MenuButton>
        }>
            {languages.map(lng =>
                <MenuItem key={lng} onClick={async () => await setLanguage(lng)}>
                    <Language language={lng}/>
                </MenuItem>
            )}
        </Menu>
    </View>;
};

interface LanguageProps {
    language: string
}

const Language = ({language}: LanguageProps) => (
    <>
        <span className={`fp fp-rounded ${languageConfig[language].countryCode}`}></span>
        <Text>&nbsp;{languageConfig[language].name}</Text>
    </>
)