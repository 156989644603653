import {createTheme, defaultDarkModeOverride} from "@aws-amplify/ui-react";
import {PaletteMode, ThemeOptions} from "@mui/material";
import {purple, teal} from "@mui/material/colors";

export const lunettesTheme = createTheme({
    name: 'lunettes-theme',
    overrides: [
        {
            colorMode: 'light',
            tokens: {
                colors: {
                    background: {
                        secondary: {value: '{colors.neutral.20.value}'}
                    }
                }
            }
        },
        defaultDarkModeOverride,
    ]
});

export const getMaterialDesignTokens = (mode: PaletteMode): ThemeOptions => ({
    palette: {
        mode,
        primary: teal,
        secondary: purple,
        /*background: {
            default: taxManagementTheme.tokens.colors.background.primary.value,
            paper: taxManagementTheme.tokens.colors.background.secondary.value,
        },
        text: {
            primary: taxManagementTheme.tokens.colors.font.primary.value,
            secondary: taxManagementTheme.tokens.colors.font.secondary.value,
        },*/
    }
});
