import {translations} from "@aws-amplify/ui-react";
import {Amplify, I18n} from "aws-amplify";
import config from "./src/aws-exports";
import {Settings} from "luxon";
import MockAdapter from "axios-mock-adapter";
import axios from "axios";
import {getEndpointsResponse} from "./mock-data";
import "@aws-amplify/ui-react/styles.css";
import "./src/styles/index.css";

Amplify.configure(config);

I18n.putVocabularies(translations);
I18n.putVocabulariesForLanguage('ja', {
    'User does not exist.': 'ユーザーが存在しません。',
    'Send code': '認証コード送信',
    'Incorrect username or password.': 'ユーザー名またはパスワードが間違っています。',
    'Your passwords must match': 'パスワードは一致しません。',
    'Signing in': 'サインイン中',
    'Confirming': '確認中',
    'Changing': '変更中',
    'Phone': '電話番号',
    'Name': '氏名',
    'Confirm SMS Code': 'SMSコード確認',
    'Confirm TOTP Code': 'TOTPコード確認',
    'Code': 'コード',
    'Current Password': '現在のパスワード',
    'Forgot your password?': 'パスワードをお忘れですか？',
});

Settings.defaultZone = 'Japan';

if (process.env.GATSBY_MOCK) {
    console.log("MOCK");
    const mock = new MockAdapter(axios);

    mock
        .onGet(/\/endpoints\/.+$/)
        .reply(200, getEndpointsResponse)
        .onDelete(/\/endpoint\/.+$/)
        .reply(200)
        .onPut(/\/endpoint\/.+$/)
        .reply(200)
        .onAny()
        .reply(config => {
            console.log(config.url);
            return [200, {}];
        });
}