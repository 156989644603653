import * as React from "react"
import {ReactNode, useState} from "react"
import {node} from "prop-types";
import {graphql, useStaticQuery} from "gatsby";
import {useI18next} from "gatsby-plugin-react-i18next";
import NavBar, {Navigation} from "./NavBar";
import {Authenticator, Button, Flex, Heading, useTheme, View} from "@aws-amplify/ui-react";
import {
    DefaultComponents
} from "@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents";
import Copyright from "./Copyright";
import LanguageSelector from "./LanguageSelector";
import PageRibbon from "./PageRibbon";
import config from 'aws-exports';
import ColorModeSwitcher from "./ColorModeSwitcher";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {AuthFormFields} from "@aws-amplify/ui";
import {Helmet} from 'react-helmet';
import LunettesProvider from "provider/LunettesProvider";


interface LayoutProps {
    children: ReactNode
}

const authenticatorComponents = (heading: string): DefaultComponents => ({
    Header() {
        const {tokens} = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Heading level={1}>{heading}</Heading>
            </View>
        );
    },

    Footer() {
        const {tokens} = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Flex direction="column" alignItems="center">
                    <LanguageSelector/>
                    <ColorModeSwitcher/>
                    <Copyright/>
                </Flex>
            </View>
        );
    }
});

const authenticatorFormFields: AuthFormFields = {
    signIn: {
        username: {
            // override the default placeholder ("Enter your Username") since the capital "U" prevents the localization to work properly
            placeholder: "Enter your username",
        },
    },
}


function getEnvFromAwsExports() {
    const envs = config.aws_cloud_logic_custom
        .filter((value: { name: string, endpoint: string }) => value.name === 'lunettes' && value.endpoint)
        .map((value: { endpoint: string }) => value.endpoint?.substring(value.endpoint?.lastIndexOf('/') + 1));
    return envs && envs.length > 0 ? envs[0] : 'dev';
}

const Layout = ({children}: LayoutProps) => {
    const env = process.env.REACT_APP_BUILD_ENV || getEnvFromAwsExports();
    const {t, language} = useI18next();
    const [expanded, setExpanded] = useState(false);

    const data = useStaticQuery<Queries.SiteQuery>(graphql`
        query Site {
            site {
                siteMetadata {
                    title
                    description
                    menuLinks {
                        name
                        link
                    }
                }
            }
            package {
                version
            }
        }
    `);
    const siteTitle = t(data.site!.siteMetadata.title);

    return <LunettesProvider>
        <Helmet
            title={siteTitle}
            meta={[
                {name: "description", content: data.site!.siteMetadata.description},
            ]}
            htmlAttributes={{lang: language}}/>
        {env !== 'prod' && <PageRibbon env={env}/>}
        <Authenticator key={language} hideSignUp
                       components={authenticatorComponents(siteTitle)}
                       formFields={authenticatorFormFields}>
            {() =>
                <Flex>
                    <NavBar siteTitle={siteTitle}
                            menuLinks={data.site!.siteMetadata.menuLinks}
                            version={data.package!.version}/>
                    <Button
                        className="menu-button"
                        onClick={() => setExpanded(!expanded)}
                        ariaLabel="Menu button">
                        {expanded ? <CloseIcon style={{width: '1.5rem', height: '1.5rem'}}/> :
                            <MenuIcon style={{width: '1.5rem', height: '1.5rem'}}/>}
                    </Button>
                    {expanded &&
                        <View className={`mobile-nav${expanded ? ' expanded' : ''}`}>
                            <Navigation siteTitle={siteTitle}
                                        menuLinks={data.site!.siteMetadata.menuLinks}
                                        version={data.package!.version!}
                                        onClick={() => setExpanded(false)}/>
                        </View>
                    }
                    <main>
                        {children}
                    </main>
                </Flex>
            }
        </Authenticator>
    </LunettesProvider>
};

Layout.propTypes = {
    children: node.isRequired,
};

export default Layout;
