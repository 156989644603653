import React, {createContext, PropsWithChildren, useContext, useEffect, useMemo} from 'react';
import {getMaterialDesignTokens, lunettesTheme} from "theme/lunettesTheme";
import {Authenticator, ColorMode, ThemeProvider} from "@aws-amplify/ui-react";
import {PaletteMode, ThemeProvider as MaterialThemeProvider, useMediaQuery} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {node} from "prop-types";
import {useI18next} from "gatsby-plugin-react-i18next";
import {createTheme as createMaterialTheme} from "@mui/material/styles";
import {Hub, I18n} from "aws-amplify";
import * as muiLocales from "@mui/material/locale";
import * as datePickerLocales from '@mui/x-date-pickers/locales';
import {useLocalStorageValue} from "@react-hookz/web";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {Settings} from "luxon";

type MuiLocales = keyof typeof muiLocales;
type DatePickerLocales = keyof typeof datePickerLocales;

function getMaterialPaletteMode(colorMode: "system" | "light" | "dark", prefersDarkMode: boolean): PaletteMode {
    switch (colorMode) {
        case "system":
            return prefersDarkMode ? "dark" : "light";
        case "light":
            return "light";
        case "dark":
            return "dark";
    }
}

const LOCALE_MAP: {
    [name: string]: { date: string, mui: MuiLocales, datePicker: DatePickerLocales }
} = {
    'ja': {date: "ja", mui: "jaJP", datePicker: "enUS"},
    'en': {date: "en-us", mui: "enUS", datePicker: "enUS"}
}

interface LunettesContextInterface {
    colorMode: ColorMode;
    setColorMode: (colorMode: ColorMode) => void;
    setLanguage: (language: string) => Promise<void>;
}

function createCtx<A extends {} | null>() {
    const ctx = createContext<A | undefined>(undefined);

    function useCtx() {
        const c = useContext(ctx);
        if (c === undefined)
            throw new Error("useCtx must be inside a Provider with a value");
        return c;
    }

    return [useCtx, ctx.Provider] as const; // 'as const' makes TypeScript infer a tuple
}

const [use, MyProvider] = createCtx<LunettesContextInterface>();
export const useLunettes = use;

const LunettesProvider = ({children}: PropsWithChildren) => {
    const {language, changeLanguage, navigate} = useI18next();
    const colorModeLocalStorage = useLocalStorageValue<ColorMode>('colorMode', {
        defaultValue: 'system',
        initializeWithValue: false
    });
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // navigate to / when user signs out
    Hub.listen('auth', async data => {
        if (data.payload.event === 'signOut') {
            await navigate('/');
        }
    });

    useEffect(() => {
        colorModeLocalStorage.fetch();
    }, []);

    const handleColorModeChange = (colorMode: ColorMode) => {
        if (colorMode !== 'system') {
            colorModeLocalStorage.set(colorMode);
        } else {
            colorModeLocalStorage.remove();
        }
    };

    function setLanguage(language: string) {
        I18n.setLanguage(language);
        Settings.defaultLocale = language;
    }

    setLanguage(language);

    const themeWithLocale = useMemo(
        () => createMaterialTheme(
            getMaterialDesignTokens(getMaterialPaletteMode(colorModeLocalStorage.value!, prefersDarkMode)),
            muiLocales[LOCALE_MAP[language].mui],
            datePickerLocales[LOCALE_MAP[language].datePicker]
        ),
        [language, colorModeLocalStorage.value, prefersDarkMode],
    );

    return (
        <MyProvider value={{
            colorMode: colorModeLocalStorage.value!,
            setColorMode: handleColorModeChange,
            setLanguage: async (language) => {
                setLanguage(language);
                await changeLanguage(language);
            },
        }}>
            <ThemeProvider theme={lunettesTheme}
                           colorMode={colorModeLocalStorage.value}>
                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={LOCALE_MAP[language].date}>
                    <Authenticator.Provider>
                        <MaterialThemeProvider theme={themeWithLocale}>
                            <div id="___lunettes">
                                {children}
                            </div>
                        </MaterialThemeProvider>
                    </Authenticator.Provider>
                </LocalizationProvider>
            </ThemeProvider>
        </MyProvider>
    )
};

LunettesProvider.propTypes = {
    children: node.isRequired,
};

export default LunettesProvider;