import * as React from "react"
import {Button, Collection, Divider, Flex, Heading, Icon, Text, useAuthenticator, View} from "@aws-amplify/ui-react";
import {Link, useI18next} from "gatsby-plugin-react-i18next";
import LanguageSelector from "./LanguageSelector";
import Copyright from "./Copyright";
import ColorModeSwitcher from "./ColorModeSwitcher";
import LogoutIcon from "@mui/icons-material/Logout";

interface NavbarProps {
    readonly siteTitle: string,
    readonly menuLinks: readonly {
        readonly name: string,
        readonly link: string
    }[],
    readonly version?: string,
    readonly onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

export const Navigation = (props: NavbarProps) => {
    const {t, path} = useI18next();
    const {signOut, user} = useAuthenticator(context => [context.signOut, context.user]);

    return <nav className="sidebar-nav">
        <Flex className="sidebar-nav-container" direction="column" justifyContent="space-between">
            <View>
                <Heading level={4}>
                    {props.siteTitle}&nbsp;{props.version}
                </Heading>
                <Text fontSize="0.8rem">{t('user')}: {user.username}</Text>
                <Divider/>
                <Collection type="list" items={props.menuLinks.slice()} gap="1.5rem">
                    {(item) => <Link key={item.name} onClick={props.onClick}
                                     className={`nav-link${path === item.link ? " current" : ""}`}
                                     to={item.link}>{t(`menu.${item.name}`)}</Link>}
                </Collection>
                <Divider/>
            </View>
            <Flex direction="column">
                <ColorModeSwitcher/>
                <LanguageSelector/>
                <Button onClick={signOut}>
                    <Icon ariaLabel="Logout" as={LogoutIcon}/> {t('button.signOut')}
                </Button>
                <Copyright/>
            </Flex>
        </Flex>
    </nav>;
}

export default (props: NavbarProps) =>
    <aside className="sidebar">
        <Flex className="sidebar-inner" direction="column">
            <Navigation {...props} />
        </Flex>
    </aside>;